/* OTHER */
.dot_grid1 {
    position: absolute;
    z-index: -1;
    right: -969px;
    top: -76px;
}
.dot_grid2 {
    position: absolute;
    z-index: -1;
    left: -990px;
    top: -176px;
}


/* MAIN SECTION StART */
main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-text-area {
    margin-top: 130px;
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
}
.left-side-decoration {
    display: flex;
    flex-direction: column;
    gap:30px;
    width: auto;
    margin-right: 15%;
}
.right-side-decoration {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: auto;
    margin-left: 15%;
}
.icon-card {
    z-index: 2;
}
#blur1 {
    position: absolute;
    width: 256px;
    height: 256px;
    margin: -24px -118px;
    z-index: 1;
    background: #84E7D2;
    filter: blur(132px);
}

.main-text-container {
    min-width: 593px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;
    z-index: 2;
}
.main-text-container > h1{
    font-size: 48px;
    color:#292828 ;
    user-select: none;
}
.main-text-container span {
    background: linear-gradient(92.12deg, #4FEDD1 10.89%, #7CE8F7 75.92%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.main-text-secondary {
    font-size: 24px;
    max-width: 500px;
    text-align: center;
}
#main-text-colored {

}
#main-button-container {
    position: relative;
    margin-top: 40px;
}
.main-button {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: #FFFF;
    padding: 16px 30px;
    border-radius: 130px;
    font-size: 24px;
    z-index: 2;
    transition: all 0.2s ease-out;
    background: linear-gradient(94.16deg, #13BAC7 5.55%, #7AE2CF 86.62%);
}
.main-button:hover{
    filter:brightness(1.07);
}

/* MAIN SECTION END */

/* ABOUT US */
.about-us-main-container {
    position: relative;
    display: flex;
    flex-direction: column;  
    gap:50px;
    align-items: center;
    max-width: 1000px;
    margin-top: 10px;
}

.about-us-item {
    display: flex;
    gap:30px;
    justify-content: center; 
    margin-top: 25px;
}
.about-us-img {
    transform: scale(1);
    transition: 0.3s;
    border-radius: 32px;
    width: 150px;
    height: 143px;
}
.about-us-img-rentgen 
{
    transform: scale(1);
    transition: 0.3s;
    border-radius: 32px;
    width: 400px;
    height: auto;
    object-fit: cover;
}
.about-us-text {
    z-index:2;
    font-size: 18px;
    padding: 20px 60px;
    background-color: #EAFEFA;
    border: 0px solid #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    transition: 0.3s;
}
.about-us-item:hover > .about-us-text {
    box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.1);
}
.about-us-item:hover > .about-us-img {
    transform: scale(1.02);
    box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.1);
}

/* DOCTORS SECTION */

.doctors-cards-section {
    margin: 60px 0px 0px 0px;
    display: grid;
    width: 80%;
    max-width: 1200px;
    gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(250px,300px));
    justify-content: center;
}

/* SERVICE SECTION */
.services-section {
    background: #e8fdfa;
    padding:0px 0px 50px 0px;
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    z-index: 1;
}
.services-main-container {
    display: flex;
    justify-content: center;
    margin: 40px;
    max-width: 1200px;
    gap:40px;
}
.consultation-img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 25px;
}
.service-img {

}
#consultation-text {
    position: absolute;
    font-size: 34px;
    text-transform: uppercase;
    color:#fff;
    bottom: 11px;
    text-align: center;
    width: 100%;
}
.consultation-img-container {
    min-width: 285px;
    flex-shrink: 1;
    height: 428px;
    position: relative;
    
}
.services-items {
    display: flex;
    max-width: 570px;
    flex-wrap: wrap;
    align-items:baseline;
    gap:50px;
    
}
.services-item {
    display: flex;
    flex-direction: column;
    gap:10px;
    max-width: 144px;
    justify-content: center;
    align-items:center;
}
.service-name {
    font-size:18px;
    max-width: 140px;
    text-align: center;
    font-weight: 600;
    color:#292828;
}

/* CONTACT */
.content {
    width: 100%;
    height: 70%;
}
.contacts-section {
    width:100%;
}

.contacts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    gap:10%;
}
.work_schedule-container {
    display: flex;
    flex-direction: column;
}
.work_schedule-maintext {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #333333;
}
.work_schedule-text {
    margin-top: 50px;
    align-self: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.25em;
    text-align: right;
}
.contact-card-container {
    display: flex;
    flex-direction:column;
    width: 100%;
    height: 100%;
    align-items: center;
}
.contact-card-maintext {
    align-self: center;
    font-size: 36px;
    font-weight: 700;
    color: #333333;
}
.contact-card-text {
    margin-top: 20px;
    width: 80%;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: left;
}
.map-container {
    margin-top: 35px;
    position: relative;
    transition: 0.3s;
}
.map-button {
    position: absolute;
    width: 56px;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    filter: brightness(1) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0));
    transition: 0.3s;
}
.map-container:hover .map-button{
    filter: brightness(1.70) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.4));
    transform: scale(1.2);
    
}
.map-container:hover {
    filter: brightness(1);
    transform: scale(1.02);
}

/* ADAPTIVE */
@media screen and (max-width:790px) {
    .rentgen {
        flex-direction: column;
        align-items:center;
        margin-top: 60px;
    }
    
}
@media screen and (max-width:990px) {
    .main-text-secondary {
        font-size: 18px;
    }
    
    .services-main-container {
        flex-direction: column;
    }
    .consultation-img-container {
        height: 185px;
    }
    .services-items {
        justify-content: center;
    }
    .services-item {
        width: 100px;
    }
    .service-name {
        text-align: center;
    }
    .services-items img{
        width: 100%;
    }
    #consultation-text {
        text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.582);
    }
    .right-side-decoration {
        display: none;
    }
    .left-side-decoration {
        display: none;
    }
   
}
@media screen and (max-width:600px) {
    .about-us-img-rentgen {
        width: 100%;
    }   
    .main-button {
        font-size: 20px;
    }
    .main-text-secondary {
        font-size: 18px;
    }
    .main-text-container {
        min-width: 100%;
        margin-top: -40px;
    }
    .main-text-container h1{
        font-size: 28px;

    }
    .about-us-main-container {
        width: 95%;
    }
    .about-us-item {
        flex-direction: column;
        align-items: center;
        width: 100%;
        
    }
    .about-us-text {
        order: 2;
        width: 95%;
        padding: 20px 20px;
    }
   
}
