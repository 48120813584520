.footer-container {
    position: relative;
    padding: 40px 60px;
    height: 100px;
    background-color: #1E2827;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.footer-container ul {
    list-style: none;
    color:#D1D1D1;
}
.footer-container a {
    color:#D1D1D1;
}
.footer-container ul li {
    margin-bottom: 10px;
}
.line {

}
.footer-contacts {
    
}
.footer-social-inside-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.footer-social-inside-container img {
    width: 25px;
    height:25px;
}
.author-text-container {
    position: absolute;
    right: 20px;
    bottom: 10px;
    padding: 0px 10px;
}
.footer-container .author-text  {
    color:#5e5e5e;
    font-size: 14px;
    margin: 0px 10px 0px 0px;
}

@media screen and (max-width:550px) {
    .footer-container {
        height: 290px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 15px 60px;
        justify-content: flex-start;
    }
}
@media screen and (max-width:318px) {
    .footer-container {
        height: 350px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 15px 60px;
        justify-content: flex-start;
    }
}