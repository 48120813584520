.banner-container {
    max-width: 800px;
    margin-top: 200px;
    border: 1px solid #B3DFD7;
    border-radius: 20px;
    background-color: rgba(238, 255, 252, 1);
    padding: 21px 122px;
    box-shadow: 2px 4px 9px 0px rgba(0, 0, 0, 0.05);
    z-index: 5;
}
.banner-header-area {
    display: flex;
    gap:13px;
    align-items: center;
}
.header-decoration {
    display: flex;
    flex-direction: column;
    width: max-content;
}
.header-decoration-img {
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.banner-info-container {
    display: flex;
    gap: 25px;
    align-items: center;
    margin: 30px 0px 0px;
}
.banner-img-container {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
}
.banner-img {
    width: 100%;
    height: 100%;

}


/* ADAPTIVE */
@media screen and (max-width:890px) {
    .banner-container {
        padding: 21px 45px;  
    }
}

@media screen and (max-width:490px) {
    .banner-info-container {
        flex-direction: column; 
    }
    .banner-container {
        width: 100%;
    }
}
