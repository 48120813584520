header {
    display: flex;
    justify-content: space-between;
    padding: 0% 10%;
    align-items: center;
    height: 85px;
}

.logo-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap:0px 10px;
    margin: 10px 0px;
}

.logo-container img {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
}

.logo-container p {
    color: black;
}

#logo_name1 {
    align-self: flex-end;
    font-size: 32px;
}

#logo_name2 {
    align-self: flex-start;
}

nav ul {
    list-style-type: none;
    display: flex;
    margin-bottom: 19px;
}

nav li {
    margin: 0px 20px;
}
.nav-table-item a {
    color: black;
}



/* ADAPTIVE */
.hamburger {
    display: none;
    width: 40px;
    height: 40px;
    transition: 0.3s;
}
.fixed {
    position: fixed;
}
.hamb-line-opened {
    z-index: 2;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: black;
    margin: 10px 0px;
    transition: 0.3s;
}

.hamb-line-closed {
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: black;
    margin: 10px 0px;
    transition: 0.3s;
}
.hamb-line-closed:nth-child(2) {
    
    display: none;
    margin: 0 auto;
}
.hamb-line-closed:nth-child(1) {
    position: absolute;
    transform: rotate(40deg);
    top: 10px;
    background-color: aliceblue;
}
.hamb-line-closed:nth-child(3) {
    transform: rotate(-40deg);
    position: absolute;
    top: 10px;
    background-color: aliceblue;
}

.mobile-nav-container-disable {
    visibility: hidden;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0px;
    left: 0px;
    background-color: rgba(16, 31, 28, 0.842);
    backdrop-filter: blur(4px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.mobile-nav-container-active {

    width: 100vw;
    height: 100vh;
    top:0px;
    left: 0px;
    background-color: rgba(16, 31, 28, 0.842);
    backdrop-filter: blur(4px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center; 
    overflow: hidden;
}
.mobile-nav-table {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.mobile-nav-table li a {
    font-size: 24px;
    color: aliceblue;
    font-weight: 300;
    margin: 30;
}


@media screen and (max-width:1099px) {
    .hamburger {
        display:block;
        right: 10%;
        z-index: 11;
    }
    nav ul {
        display: none;
    }
}

@media screen and (max-width:600px) {
    .logo-container img {
        width: 33px;
        height: auto;
    }
    #logo_name1 {
        font-size: 20px;
    }
    #logo_name2 {
        font-size: 18px;
    }
    .hamburger {
        width: 30px;
        height: 30px;
    }
    .hamb-line-opened {
        margin: 8px 0px;
    }
}
