.doctor-card-main-container {
    display: flex;
    flex-direction: column;
    padding: 18px 18px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0px 4px 33px 10px rgba(0, 0, 0, 0.06);
}

.doctor-card-img {
    border-radius: 24px;
    width: 100%;
}
.doctor-name {
    font-size: 22px;
    margin: 18px 0px 0px 15px;
    color:rgba(0, 0, 0, 1);
}
.doctor-status {
    font-size: 18px;
    color: rgba(136, 136, 136, 1);
    margin: 18px 0px 0px 15px;
}