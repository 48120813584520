.contact-item-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 420px;
    height: 510px;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.09);
    border-radius: 31px;
    align-items:center;
    justify-content: center;
    margin: 140px 20px;
}
.blur {
    height: 70px;
    width: 96px;
    position: absolute;
    top: -60px;
    background-color: aquamarine;
    background: #33D2B9;
    filter: blur(37px);
    z-index: -1;
}
.icon {
    position: absolute;
    width: 100px;
    top:-60px;
    border: 0px solid #fff;
    border-radius: 27px;
}
.icon-img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:975px) {
    .contact-item-container:nth-child(1) {
        margin: 185px 0px;
    }
    .contact-item-container {
        width: 99%;
        max-width: 420px;
        margin: 40px 0px;
        height: 494px;
    }
    
    .contact-item-container p {

        font-size: 1.3em;
    }
    .map-container img:nth-child(2) {
       width: 100%;
    }  
    .work_schedule-text {
        letter-spacing: 0.12em;
    }
}