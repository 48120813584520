@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,400;1,700&display=swap');
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  /* outline: 1px #f00 solid; */
}
*::selection {
  background: rgba(132, 231, 210, 1); /* Safari */
  }
*::-moz-selection {
  background: rgba(132, 231, 210, 1); /* Firefox */
  }
html {
  height: 100%;
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  background-color: #F9FFFF;
  height: 100%;
  width: 100%;
}
li {
  list-style: none;
}
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
main {
  flex: 1 1 auto;
  padding: 20px;
}
h2 {
  font-size: 32px;
  font-weight:700;
}

