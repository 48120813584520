.select {
   position: relative;
   max-width: 700px;
   border-radius: 11px;
   overflow: hidden;
   box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.11);
   transition: 0.2s;
   
}
.select:hover #catalog-selection {
   background-color: rgb(58, 67, 75);
   color: #e2e2e2;
}
.select:hover .custom-arrow {
   background-color: rgb(58, 67, 75);
}
.catalog-main-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
#catalog-selection {
   font-size: 16px;
   padding: 18px 120px 18px 30px;
   border:0px;
   outline:0px;
   transition: 0.2s;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-color: #fff;
}
option {
   color: black;
   background-color: #FFFFFF;
   -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-arrow {
   user-select: none;
   pointer-events: none;
   display: flex;
   right:0px;
   top: 2px;
   position: absolute;
   width: 90px;
   height: 100%;
   background-color: #FFFFFF;
   justify-content: center;
   align-items: center;
   transition: 0.2s;

}
.arrow-line-bottom::after {
   content: url("./img-content/line_down.png");  
}

.catalog-table {
   max-width: 1200px;
   margin: 50px 0px;
   transition: 0.3s;
   border-collapse:separate; 
   border-spacing: 0 1em;
   
}
.catalog-table td {
   margin: 320px 0px;
   padding: 0px 15px;
}

@media screen and (max-width:400px) {
   .catalog-table td {
      font-size: 14px;
      padding: 0px 7px;
   }
   .catalog-table {
      max-width: 100vw;
      margin: 50px 10px;
      transition: 0.3s;
      border-collapse:separate; 
      border-spacing: 0 0.7em;  
   }
   #catalog-selection {
      padding: 18px 18px 18px 30px;
      font-size: 16px;
   }
}